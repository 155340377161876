"use strict";

const messages = {
  en: {
    dashboard: {
      loading: "Loading...",
      status: "Status",
      extension: "Extension",
    },
    header: {
      title: "Call center",
      home: "Home",
      reports: "Reports",
      language: "Français",
      supervisor: "Supervisor",
      logout: "Logout",
    },
    agentActions: {
      actions: "Actions",
      login: "Login",
      logout: "Logout",
      pause: "Pause",
      unpause: "Unpause",
      extensions: "Extension:",
      noMembers: "This agent is not a member of any queue, login is disabled.",
    },
    agentSessionsReport: {
      startTime: "Start time",
      endTime: "End time",
      extension: "Extension",
      answeredCalls: "Inbound calls",
      outboundCalls: "Outbound calls",
      talkTime: "Inbound talk time",
      outboundTime: "Outbound talk time",
      wrapupTime: "Wrapup time",
      pauseTime: "Pause time",
      availableTime: "Available",
      duration: "Duration",
    },
    agentSessionsActivityReport: {
      totals: "Totals",
      agent: "Agent",
      totalInboundCalls: "Inbound calls",
      totalOutboundCalls: "Outbound calls",
      totalConversation: "Talk time (inbound and outbound)",
      totalAvailableTime: "Available time",
      totalDuration: "Session duration",
      totalPauseTime: "Pause time",
      pctConversation: "Talk time (%)",
      totalTalkTime: "Talk time (inbound)",
      totalOutboundTime: "Talk time (outbound)",
      avgTalkTime: "Average talk time (inbound)",
      avgOutboundTime: "Average talk time (outbound)",
    },
    agentSessionsSummaryReport: {
      totals: "Totals",
      agent: "Agent",
      availableTime: "Available",
      answeredCalls: "Answered calls",
      outboundCalls: "Outbound calls",
      pauses: "Pauses",
      duration: "Online duration",
      talkTime: "Inbound talk time",
      outboundTime: "Outbound talk time",
      pauseTime: "Pause time",
      wrapupTime: "Wrapup time",
    },
    inboundCallsReport: {
      title: "Inbound calls",
      date: "Date",
      duration: "Duration",
      queue: "Queue",
      callerID: "Caller ID",
      callerName: "Caller name",
      language: "Language:",
      position: "Position:",
      holdTime: "Hold time:",
      talkTime: "Talk time:",
      id: "ID:",
      notes: "Notes:",
      agent: "Agent",
      result: "Result",
      actions: "Actions",
      result1: "Completed by agent",
      result2: "Completed by caller",
      result3: "Transfer to",
      result4: "Abandon",
      result5: "Left via key",
      download: "Download",
      play: "Play",
      details: "Details",
      close: "Close",
    },
    inboundCallsSummaryReport: {
      totals: "Totals",
      queue: "Queue",
      calls: "Presented calls",
      completedCalls: "Completed calls",
      pctCompletedCalls: "(%)",
      avgHoldTime: "Hold time (avg)",
      maxHoldTime: "Hold time (max)",
      avgTalkTime: "Talk time (avg)",
      leaveWithKey: "Left with key",
      abandons: "Abandons",
      pctAbandons: "(%)",
    },
    dailyReport: {
      totals: "Totals",
      segment: "Period",
      presentedCalls: "Presented calls",
      completedCalls: "Completed calls",
      pctCompletedCalls: "(%)",
      abandons: "Abandons",
      pctAbandons: "(%)",
      leaveWithKey: "Left with key",
      pctLeaveWithKey: "(%)",
      avgHoldTime: "Hold time (avg)",
      avgTalkTime: "Talk time (avg)",
    },
    holdTimeReport: {
      totals: "Totals",
      segment: "Hold time",
      presentedCalls: "Presented calls",
      completedCalls: "Completed calls",
      leaveWithKey: "Left with key",
      abandons: "Abandons",
    },
    outboundCallsReport: {
      title: "Outbound calls",
      date: "Date",
      duration: "Duration",
      agent: "Agent",
      destination: "Destination",
      actions: "Actions",
      id: "ID:",
      download: "Download",
      play: "Play",
      details: "Details",
      close: "Close",
    },
    outboundCallsSummaryReport: {
      totals: "Totals",
      agent: "Agent",
      totalCalls: "Total calls",
      totalDuration: "Total duration",
      avgDuration: "Average duration",
    },
    line: {
      activate: "Second line activation",
      take: "Take another call",
    },
    login: {
      title: "Call center",
      login: "Login",
      invalid: "Invalid username or password",
      username: "Username",
      password: "Password",
      submit: "Submit",
      language: "Français",
    },
    member: {
      offline: "Offline",
      wrapup: "Wrapup",
      pause: "Pause",
      available: "Available",
      ringing: "Ringing",
      conversation: "Talking",
    },
    reports: {
      title: "Reports",
      reportType: "Report type:",
      wrapupsReport: "Wrapups",
      inboundCallsReport: "Inbound calls - Details",
      inboundCallsSummaryReport: "Inbound calls - Summary",
      dailyReport: "Daily summary",
      holdTimeReport: "Hold time",
      outboundCallsReport: "Outbound calls - Details",
      outboundCallsSummaryReport: "Outbound calls - Summary",
      agentSessionsReport: "Agent sessions - Details",
      agentSessionsActivityReport: "Agent sessions - Activity",
      agentSessionsSummaryReport: "Agent sessions - Summary",
      startDate: "Start date:",
      endDate: "End date:",
      phoneNumber: "Phone number:",
      submit: "View",
      downloadCSV: "Download as CSV",
      loading: "Loading...",
      queue: "Queue:",
      agentFilter: "Agent:",
      all: "(All)",
    },
    status: {
      status: "Status",
      queues: "Queues",
      showDetails: "Show details",
      showOffline: "Show offline",
      name: "Name",
      members: "Agents",
      callsOnHold: "Calls on hold",
      averageHoldTime: "Hold time (avg)",
      completed: "Completed",
      abandons: "Abandons",
      messages: "Messages",
      agents: "Agents",
      queue: "Queue",
      memberOf: "Queues",
      callsTaken: "Calls taken",
      lastCall: "Last call",
      extension: "Extension",
      logout: "Logout",
      areYouSure: "Are you sure?",
    },
    summary: {
      onlineAgents: "Agents",
      callsOnHold: "Calls on hold",
      longestHoldTime: "Hold time",
    },
    wrapup: {
      title: "Wrapup",
      caller: "Caller",
      number: "Number",
      queue: "Queue",
      name: "Name",
      language: "Language",
      results: "Results",
      notes: "Notes",
      loading: "Loading...",
      submit: "Submit",
      submitAndLogout: "Submit and Logout",
      noCallsInProgress: "No calls in progress",
    },
    wrapupsReport: {
      title: "Wrapups",
    },
  },
  fr: {
    dashboard: {
      loading: "Chargement...",
      status: "Statut",
      extension: "Poste",
    },
    header: {
      title: "Centre d'appels",
      home: "Accueil",
      reports: "Rapports",
      language: "English",
      supervisor: "Superviseur",
      logout: "Quitter",
    },
    agentActions: {
      actions: "Actions",
      login: "Ouvrir une session",
      logout: "Fermer la session",
      pause: "Pause",
      unpause: "Sortir de pause",
      extensions: "Poste:",
      noMembers: "L'agent n'est membre d'aucune file d'attente, l'ouverture de session n'est pas disponible.",
    },
    agentSessionsReport: {
      startTime: "Début",
      endTime: "Fin",
      extension: "Poste",
      answeredCalls: "Appels entrants",
      outboundCalls: "Appels sortants",
      talkTime: "Durée appels entrants",
      wrapupTime: "Durée conclusions",
      outboundTime: "Durée appels sortants",
      pauseTime: "Durée pauses",
      availableTime: "Disponible",
      duration: "Durée",
    },
    agentSessionsActivityReport: {
      totals: "Totaux",
      agent: "Agent",
      totalInboundCalls: "Appels entrants",
      totalOutboundCalls: "Appels sortants",
      totalConversation: "Temps conv. (tous)",
      totalAvailableTime: "Temps disponible",
      totalDuration: "Temps en ligne",
      totalPauseTime: "Temps en pause",
      pctConversation: "Temps conv. (%)",
      totalTalkTime: "Temps conv. (entrant)",
      totalOutboundTime: "Temps conv. (sortant)",
      avgTalkTime: "Temps moyen (entrant)",
      avgOutboundTime: "Temps moyen (sortant)",
    },
    agentSessionsSummaryReport: {
      totals: "Totaux",
      agent: "Agent",
      availableTime: "Disponible",
      answeredCalls: "Appels entrants",
      outboundCalls: "Appels sortants",
      pauses: "Pauses",
      duration: "Durée en ligne",
      talkTime: "Durée appels entrants",
      outboundTime: "Durée appels sortants",
      pauseTime: "Durée pauses",
      wrapupTime: "Durée conclusions",
    },
    inboundCallsReport: {
      title: "Détail des appels entrants",
      date: "Date",
      duration: "Durée",
      queue: "File d'attente",
      callerID: "Numéro",
      callerName: "Nom",
      language: "Langue:",
      position: "Position:",
      holdTime: "Temps d'attente:",
      talkTime: "Temps conversation:",
      id: "Identifiant:",
      notes: "Notes:",
      agent: "Agent",
      result: "Résultat",
      actions: "Actions",
      result1: "Complété par l'agent",
      result2: "Complété par l'appelant",
      result3: "Transfert vers",
      result4: "Abandon",
      result5: "Quitté via touche",
      download: "Télécharger",
      play: "Écouter",
      details: "Détails",
      close: "Fermer",
    },
    inboundCallsSummaryReport: {
      totals: "Totaux",
      queue: "File d'attente",
      calls: "Appels présentés",
      completedCalls: "Appels complétés",
      pctCompletedCalls: "(%)",
      avgHoldTime: "Temps d'attente (moy)",
      maxHoldTime: "Temps d'attente (max)",
      avgTalkTime: "Temps conversation (moy)",
      leaveWithKey: "Quitté via touche",
      abandons: "Abandons",
      pctAbandons: "(%)",
    },
    dailyReport: {
      totals: "Totaux",
      segment: "Période",
      presentedCalls: "Appels présentés",
      completedCalls: "Appels complétés",
      pctCompletedCalls: "(%)",
      abandons: "Abandons",
      pctAbandons: "(%)",
      leaveWithKey: "Quitté via touche",
      pctLeaveWithKey: "(%)",
      avgHoldTime: "Temps d'attente (moy)",
      avgTalkTime: "Temps conversation (moy)",
    },
    holdTimeReport: {
      totals: "Totaux",
      segment: "Temps d'attente",
      presentedCalls: "Appel présentés",
      completedCalls: "Appels complétés",
      leaveWithKey: "Quitté via touche",
      abandons: "Abandons",
    },
    outboundCallsReport: {
      title: "Détail des appels sortants",
      date: "Date",
      duration: "Durée",
      agent: "Agent",
      destination: "Destination",
      actions: "Actions",
      id: "Identifiant:",
      download: "Télécharger",
      play: "Écouter",
      details: "Détails",
      close: "Fermer",
    },
    outboundCallsSummaryReport: {
      totals: "Totaux",
      agent: "Agent",
      totalCalls: "Total appels",
      totalDuration: "Durée totale",
      avgDuration: "Durée moyenne",
    },
    line: {
      activate: "Activation de la 2e ligne",
      take: "Prendre un autre appel",
    },
    login: {
      title: "Centre d'appels",
      login: "Ouverture de session",
      invalid: "Nom d'utilisateur ou mot de passe invalide",
      username: "Nom d'utilisateur",
      password: "Mot de passe",
      submit: "Soumettre",
      language: "English",
    },
    member: {
      offline: "Hors-ligne",
      wrapup: "Conclusion d'appel",
      pause: "Pause",
      available: "Disponible",
      ringing: "Sonnerie",
      conversation: "En conversation",
    },
    reports: {
      title: "Rapports",
      reportType: "Type de rapport:",
      wrapupsReport: "Conclusions d'appels",
      inboundCallsReport: "Appels entrants - Détails",
      inboundCallsSummaryReport: "Appels entrants - Sommaire",
      dailyReport: "Sommaire journalier",
      holdTimeReport: "Temps d'attente",
      outboundCallsReport: "Appels sortants - Détails",
      outboundCallsSummaryReport: "Appels sortants - Sommaire",
      agentSessionsReport: "Sessions d'agents - Détails",
      agentSessionsActivityReport: "Sessions d'agents - Activité",
      agentSessionsSummaryReport: "Sessions d'agents - Sommaire",
      startDate: "Date de début:",
      endDate: "Date de fin:",
      phoneNumber: "Téléphone:",
      submit: "Afficher",
      downloadCSV: "Télécharger en format CSV",
      loading: "Chargement...",
      queue: "File d'attente:",
      agentFilter: "Agent:",
      all: "(Tous)",
    },
    status: {
      status: "Statut",
      queues: "Files d'attente",
      showDetails: "Afficher détails",
      showOffline: "Afficher hors-ligne",
      name: "Nom",
      members: "Agents",
      callsOnHold: "Appels en attente",
      averageHoldTime: "Temps d'attente (moy)",
      completed: "Appels comp.",
      abandons: "Abandons",
      messages: "Msgs",
      agents: "Agents",
      queue: "File d'attente",
      memberOf: "Files d'attente",
      callsTaken: "Appels pris",
      lastCall: "Dernier appel",
      extension: "Poste",
      logout: "Fermer la session",
      areYouSure: "Êtes-vous certain?",
    },
    summary: {
      onlineAgents: "Agents en ligne",
      callsOnHold: "Appels en attente",
      longestHoldTime: "Temps d'attente",
    },
    wrapup: {
      title: "Conclusion d'appel",
      caller: "Appelant",
      number: "Numéro",
      queue: "File d'attente",
      name: "Nom",
      language: "Langue",
      results: "Résultats",
      notes: "Notes",
      loading: "Chargement...",
      submit: "Soumettre",
      submitAndLogout: "Soumettre et fermer la session",
      noCallsInProgress: "Aucun appel en cours",
    },
    wrapupsReport: {
      title: "Conclusions d'appels",
    },
  },
};

export default messages;

"use strict";

import Vue from "vue";
import VueI18n from "vue-i18n";
import messages from "../i18n/messages";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "./login.css";

// i18n
Vue.use(VueI18n);

const currentLocale = localStorage.getItem("locale") || "fr";

const i18n = new VueI18n({
  locale: currentLocale,
  fallbackLocale: "en",
  messages,
});

new Vue({
  el: "#app",
  i18n,
  data: {
    logo: require("../images/logo.png"),
  },
  methods: {
    changeLanguage: function () {
      const newLocale = this._i18n.locale === "fr" ? "en" : "fr";

      this._i18n.locale = newLocale;
      localStorage.setItem("locale", newLocale);
    },
  },
});

function handleLoginError() {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get("status")) {
    document.getElementsByClassName("login-error")[0].style.display = "block";
  }
}

window.addEventListener("load", handleLoginError);
